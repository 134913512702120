
.reference-view{
    &__head{
        padding-top: var(--header-body-height);
        padding-bottom: 80px;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        &-bg{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }
        &-tit{
            width: fit-content;
            min-height: 86px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            text-align: center;
            position: relative;
            padding: 16px 48px;
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border: 1px solid #fff;
                opacity: 0;
                visibility: hidden;      
                transform: scaleX(0);
                animation: scaleX 0.6s 0.3s forwards ease-out;
            }
            h2{
                position: relative;
                opacity: 0;
                visibility: hidden;                
                transform: scale(1.4);
                animation: zoomOut 0.6s forwards ease-in-out;
            }
        }
        .container{
            position: relative;
        }
    }
    &__color-title{
        width: fit-content;
        padding: 2px;
        display: block;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        font-size: 1rem;
        line-height: 1;
    }
}
@keyframes zoomOut {
    0%{
        opacity: 0;
        visibility: hidden;                
        transform: scale(1.4);
    }
    100%{
        opacity: 1;
        visibility: visible;                
        transform: scale(1);
    }
}
@keyframes scaleX {
    0%{
        opacity: 0;
        visibility: hidden;  
        transform: scaleX(0);
    }
    100%{
        opacity: 1;
        visibility: visible;    
        transform: scaleX(1);
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .reference-view{
        &__head{
            padding-bottom: 48px;
            &-tit{
                min-height: 120px;
            }
        }
    }
}
@media (min-width:1024px){
    .reference-view{
        &__head{
            &-tit{
                min-height: 140px;
            }
        }
    }
}
@media (min-width:1200px){
}

.scroll-ani{
    position: absolute;
    width: 1px;
    height: 48px;
    background-color: rgba(255, 255, 255, 0.3);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
        &__inner{
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 50%;
                background-color: #fff;
                opacity: 0;
                visibility: hidden;  
                top: 0;
                left: 0;
                animation: scrollAni 1.2s infinite linear;
            }
        }
}

@keyframes scrollAni {
    0%{
        opacity: 0;
        visibility: hidden;  
        top: 0;
    }
    20%{
        opacity: 1;
        visibility: visible;         
    }
    80%{
        opacity: 1;
        visibility: visible;         
    }
    100%{
        opacity: 0;
        visibility: hidden; 
        top: 50%; 
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .scroll-ani{
        height: 80px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

