
.reference-view{
    position: relative;
    height: 100vh;
    &__slide{
        .swiper-slide__inner{
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    &__thumb-slide{
        position: absolute;
        width: 100%;
        padding: 32px 0;
        left: 0;
        bottom: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, .3);
        .swiper-container{
            max-width: 768px;
        }
        .swiper-slide__thumb{
            position: relative;
            cursor: pointer;
            &::after{
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .3);
                top: 0;
                left: 0;
                transition: 0.1s ease-out;
            }
        }
        .swiper-slide-active{            
            .swiper-slide__thumb{
                position: relative;
                &::after{
                    background-color: transparent;
                    border: 1px solid #fff;
                }
            }
        }
    }
    &__contents{
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 5;
    }
}
.line{
    display: block;
    width: 1px;
    height: 12px;
    background-color: rgba(255, 255, 255, .3);
}

::v-deep{
    .v-btn--fixed.v-btn--right {
        z-index: 99;
        top: 6px;
        right: 6px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    ::v-deep{
        .v-btn--fixed.v-btn--right {
            top: 12px;
            right: 12px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

