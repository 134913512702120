
.thumb-btn-wrap{
    transition: 0.15s ease-out;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .thumb-btn-wrap:hover{
        border-color: var(--v-primary-base) !important;
    }
}
@media (min-width:1200px){
}

