
::v-deep{
    .v-toolbar,
    .v-toolbar__content{
        min-height: 56px;
        height: auto !important;
    }
    .v-toolbar__title{
        overflow: unset;
        text-overflow: unset;
        white-space: unset;
    }
}
