
.row{
    .info-card-wrap>.v-card.v-sheet--outlined{
        border-right-width: 0;
        border-top-width : 0;
    }
    .info-card-wrap:nth-child(2n)>.v-card.v-sheet--outlined{
        border-right-width: 1px;
    }
    .info-card-wrap:nth-child(1)>.v-card.v-sheet--outlined,    
    .info-card-wrap:nth-child(2)>.v-card.v-sheet--outlined{
        border-top-width: 1px;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row{
        .info-card-wrap:nth-child(2n)>.v-card.v-sheet--outlined{
            border-right-width: 0;
        }
        .info-card-wrap:nth-child(4n)>.v-card.v-sheet--outlined{
            border-right-width: 1px;
        }
        .info-card-wrap:nth-child(3)>.v-card.v-sheet--outlined,
        .info-card-wrap:nth-child(4)>.v-card.v-sheet--outlined{
            border-top-width: 1px;
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

