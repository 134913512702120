
.history{
    position: relative;
    &::before{
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% - 20px);
        top: 50%;
        left: 3px;
        transform: translate(0, -50%);
        background-color: var(--v-grey-lighten3);
    }
    &__row{
        position: relative;
        &::after{
            content: "";
            display: block;
            position: absolute;
            width: 8px;
            height: 8px;
            top: 8px;
            left: -22px;
            background-color: var(--v-primary-lighten5);
            border: 1px solid var(--v-primary-base);
        }
        &:last-child{
            &::before{
                content: "";
                display: block;
                position: absolute;
                width: 8px;
                height: calc(100% - 12px);
                bottom: 0;
                left: -22px;
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}

@media (min-width:576px){
}
@media (min-width:768px){
    .history{
        &::before{
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__row{
            position: relative;
            &::after{
                top: 10px;
                left: 50%;
                transform: translateX(-50%);
            }
            &:last-child{
                &::before{
                    height: calc(100% - 14px);
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
        &__row{
            &:nth-child(2n - 1){
                .history__tit{
                    text-align: right;
                }
            }
            &:nth-child(2n){
                &,
                .history__year-row,
                .history__info-row{
                    flex-direction: row-reverse;       
                }
                .history__year,
                .history__info-row{
                    text-align: right;
                }
            }
        }
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

