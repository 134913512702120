
.board-list tbody tr{
    cursor:default;
}
.board-list tr td.board-list__tit{
    padding: 4px 50px 14px 10px;
}
.board-list__txt--absolute{
    position: absolute;
    top: 2px;
    right: 0;
    &::before{
        display: none;
    }
    .v-btn.v-size--default.v-btn--icon {
        width: 28px !important;
        height: 28px !important;
        .icon--size-default{
            height: 18px;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .board-list tr td.board-list__tit{
        text-align: center;
        padding: 16px 20px;
    }
    .board-list__txt--absolute{
        position: relative;
        top: 0;
        right: unset;
        .v-btn.v-size--default.v-btn--icon {
            width: var(--btn-height) !important;
            height: var(--btn-height) !important;
            .icon--size-default{
                height: 20px;
            }
        }
    }
}
@media (min-width:1200px){
}

